var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "login row no-gutters" }, [
    _c("div", { staticClass: "col-md-4" }, [
      _c("div", { staticClass: "login-form row no-gutters" }, [
        _vm._m(0),
        !_vm.success
          ? _c("form", { staticClass: "login-form__form shadow--1 col-12" }, [
              _c("div", { staticClass: "login-form__title" }, [
                _vm._v(_vm._s(_vm.headerText)),
              ]),
              _c(
                "div",
                { staticClass: "login-form__input-container" },
                [
                  _c("form-input", {
                    staticClass: "login-form__input",
                    attrs: {
                      type: "password",
                      placeholder: "New Password",
                      autofocus: "",
                      autocomplete: "off",
                    },
                    model: {
                      value: _vm.password,
                      callback: function ($$v) {
                        _vm.password = $$v
                      },
                      expression: "password",
                    },
                  }),
                  _c("form-input", {
                    staticClass: "login-form__input",
                    attrs: {
                      type: "password",
                      placeholder: "Retype New Password",
                      autocomplete: "off",
                    },
                    model: {
                      value: _vm.confirmPassword,
                      callback: function ($$v) {
                        _vm.confirmPassword = $$v
                      },
                      expression: "confirmPassword",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "flex-direction": "column",
                    "margin-bottom": "30px",
                  },
                },
                _vm._l(_vm.requirements, function (requirement) {
                  return _c(
                    "div",
                    {
                      key: requirement.text,
                      staticClass: "requirement-container",
                    },
                    [
                      requirement.test()
                        ? _c("div", {
                            staticClass: "circle-check",
                            attrs: { "aria-hidden": "true" },
                          })
                        : _c("div", {
                            staticClass: "circle-empty",
                            attrs: { "aria-hidden": "true" },
                          }),
                      _c("div", { staticClass: "requirement-text" }, [
                        _vm._v(_vm._s(requirement.text)),
                      ]),
                    ]
                  )
                }),
                0
              ),
              _c("div", [
                _vm.formValid
                  ? _c(
                      "button",
                      {
                        staticClass: "login-form__button",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.submit.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("Update Password")]
                    )
                  : _c(
                      "button",
                      {
                        staticClass: "login-form__buttondisabled",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                          },
                        },
                      },
                      [_vm._v("Update Password")]
                    ),
              ]),
            ])
          : _c("form", { staticClass: "login-form__form shadow--1 col-12" }, [
              _c("div", { staticClass: "login-form__title" }, [
                _vm._v("You've updated your password!"),
              ]),
              _c("div", [
                _c(
                  "button",
                  {
                    staticClass: "login-form__button",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.pushLogin.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("Sign In")]
                ),
              ]),
            ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "login-form__logo-container col-12" }, [
      _c("div", { staticClass: "login-form__logo" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }