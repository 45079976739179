<template>
  <div class="login row no-gutters">
    <div class="col-md-4">
      <div class="login-form row no-gutters">
        <div class="login-form__logo-container col-12">
          <div class="login-form__logo" />
        </div>

        <form v-if="!success" class="login-form__form shadow--1 col-12">
          <div class="login-form__title">{{headerText}}</div>

          <div class="login-form__input-container">
            <!-- <form-input
              v-model="invitationCode"
              class="login-form__input"
              type="text"
              placeholder="Invitation Code"
              autofocus
            />-->
            <!-- <form-input v-model="email" :disabled="true" class="login-form__input" type="email" placeholder="Email" /> -->
            <form-input
              v-model="password"
              class="login-form__input"
              type="password"
              placeholder="New Password"
              autofocus
              autocomplete="off"
            />
            <form-input
              v-model="confirmPassword"
              class="login-form__input"
              type="password"
              placeholder="Retype New Password"
              autocomplete="off"
            />
          </div>
          <div style="display: flex; align-items: center; flex-direction: column; margin-bottom: 30px">
            <div class="requirement-container" v-for="requirement in requirements" :key="requirement.text">
              <div v-if="requirement.test()" class="circle-check" aria-hidden="true"></div>
              <div v-else class="circle-empty" aria-hidden="true"></div>
              <div class="requirement-text">{{requirement.text}}</div>
            </div>
          </div>
          <div>
            <button v-if="formValid" class="login-form__button" @click.prevent="submit">Update Password</button>
            <button v-else class="login-form__buttondisabled" @click.prevent="">Update Password</button>
          </div>
        </form>
        <form v-else class="login-form__form shadow--1 col-12">
          <div class="login-form__title">You've updated your password!</div>
          <div>
            <button class="login-form__button" @click.prevent="pushLogin">Sign In</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import FormInput from "components/cleverly/FormInput";

export default {
    name: "SetPassword",
    data() {
        return {
            email: "",
            invitationCode: "",
            password: "",
            confirmPassword: "",
            requirements: [
                {
                    text: "8 characters minimum",
                    test: () => { return this.password.length >= 8 }
                },
                {
                    text: "1 uppercase letter",
                    test: () => { return (/[A-Z]/.test(this.password)); }
                },
                {
                    text: "1 lowercase letter",
                    test: () => { return (/[a-z]/.test(this.password)); }
                },
                {
                    text: "1 number or special character",
                    test: () => { return /[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(this.password) || /\d/.test(this.password)}
                },
                {
                    text: "Retype password",
                    test: () => { return this.password == this.confirmPassword && this.password.length > 0}
                },
            ],
            success: false
        };
    },
    mounted() {
        if (this.$route.query.invitationCode != undefined)
            this.invitationCode = this.$route.query.invitationCode;
        if (this.$route.query.email != undefined)
            this.email = this.$route.query.email;
    },
    methods: {
        submit() {
            // Do validation
            if (
                this.email.length == 0 ||
        this.invitationCode.length == 0 ||
        this.password.length == 0
            ) {
                this.toast("Please fill out all fields.", "error");
                return;
            }
            if (this.password != this.confirmPassword) {
                this.toast("Passwords do not match.", "error");
                return;
            }
            let payload = {
                email: this.email,
                invitationCode: this.invitationCode,
                password: this.password
            };
            this.setPasswordForUser(payload).then(data => {
                if (!data.success) {
                    return this.toast(
                        "We could not set  your password, please contact support.",
                        "error"
                    );
                } else {
                    this.success = true
                }
            });
        },
        pushLogin() {
            this.$router.push({ name: 'login' })
        },
        ...mapActions("UserStore", ["setPasswordForUser"])
    },
    computed: {
        headerText() {
            return `Enter new password for ${this.$route.query.email}`;
        },
        formValid() {
            for (const requirement of this.requirements) {
                if (!requirement.test()) return false
            }

            return true
        }
    },

    components: {
        FormInput
    }
};
</script>

<style lang="scss" scoped>
.login {
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  padding: 0 20px;
}
@import "~scss/variables";
@import "~scss/mixins";
.circle-empty {
  display: inline-block;
  border: 3px solid #fff;
  border-radius: 15px;
  box-shadow: 0 0 2px #888;
  height: 15px;
  width: 15px;
}
.circle-check {
  background-color: $brand_color;
  display: inline-block;
  border: 3px solid #fff;
  border-radius: 15px;
  box-shadow: 0 0 2px #888;
  height: 15px;
  width: 15px;
}
.requirement-container {
  display: flex;
  align-content: flex-start;
  width: 50%;
  margin-bottom: 2px;
}
.requirement-text {
  display: inline-block;
  margin-left: 5px;
}
.login-form {
  display: flex;
  justify-content: center;

  &__logo-container {
    display: flex;
    justify-content: center;
    margin-bottom: spacing(xxl);
  }

  &__logo {
    width: 228px;
    height: 110px;
    background-image: url("../img/g_hub_white.svg");
    background-size: cover;
  }

  &__form {
    max-width: 500px;
    padding: spacing(xl);
    padding-top: spacing(sm);
    background-color: #fff;
    border-radius: 3px;
  }

  &__title {
    margin-bottom: spacing(sm);
    font-weight: bold;
  }

  &__input-container {
    margin-bottom: spacing(sm);
  }

  &__button {
    padding: spacing(xxs) spacing(xl);
    margin-bottom: spacing(md);
    background-color: $brand_color;
    color: #fff;
    border: 0;
    border-radius: 3px;
  }

  &__buttondisabled {
    padding: spacing(xxs) spacing(xl);
    margin-bottom: spacing(md);
    background-color: grey;
    color: #fff;
    border: 0;
    border-radius: 3px;
  }

  &__link {
    color: $brand_color;
  }
}
</style>
